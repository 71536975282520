@media print {
  .header-top,
  .c-hamburger,
  .region-banner,
  .navigation-wrapper,
  .tabs-wrapper,
  .sharethis-buttons {
    display: none !important;
  }
  .header-wrapper {
    box-shadow: none !important;
  }
  .content-wrapper {
    background: none !important;
  }
  .doormat-wrapper {
    background: none !important;
  }
  .doormat-wrapper .block--bean--contact,
  .doormat-wrapper .block--bean--contact a,
  .doormat-wrapper h2 {
    color: #262626 !important;
  }
  .footer-wrapper {
    background: none !important;
  }
}
